<template>
  <div class="text-center" id="about">
    <div class="container">
      <div class="card border border-primary mx-auto mt-5">
        <div class="card-body">
          <h1 class="text-primary text-uppercase">about us</h1>
          <p class="text-justify">
            Carixin is the industry new in mobile vehicle repair, maintenance,
            and inspection, offering 10+ services to consumers and fleets across
            the U.S. Our mission is to leverage technology to service any
            vehicle, any place, any time. We’ve built the mobility platform of
            the future, enabling us to service vehicles at the point of need,
            increase asset yield, and lower total cost. Our mobile model and
            cloud-based platform replace shops’ physical infrastructure –
            allowing us to charge fleet owners less, pay more than shops, and
            attract top-tier mechanics and ASE-certified technicians. Above all,
            we value our customers’ happiness and satisfaction, whether we’re
            changing the brakes on a family’s minivan or enabling a commercial
            fleet of 3,000 to increase utilization. With more than 1.3 million
            vehicles on the platform and one of the highest Net Promoter Scores
            in the business, we’re speeding towards our vision to enable quality
            auto services for any vehicle, any place, any time.
          </p>
        </div>
      </div>
    </div>
    <div class="card blue-gradient text-white mt-5 depth-0">
      <div class="card-body my-4">
        <h3 class="text-uppercase text-primary text-white">how can we help ?</h3>
        <p class="text-center">
          Our service team is available 7 days a week, Monday - Friday from 6 AM
          to 5 PM PST, Saturday - Sunday 7 AM - 4 PM PST.
        </p>
        <p class="text-center">
          1 (888) 369-2206 · hi@carixin.com
        </p>
        <a class="btn btn-primary btn-rounded mt-3" href="#" role="button">Get a quote</a>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/partiels/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>
